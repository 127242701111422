<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="140px">
      <el-form-item label="商户名称" prop="merchantName" :rules="rules">
        <el-input v-model="formData.merchantName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" :rules="rules">
        <el-input v-model="formData.companyName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="企业证件号" :rules="rules">
        <el-input v-model="formData.companyIdentity" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="营业执照" :rules="rules">
        <FileUpload :isMulti="true" :name.sync="formData.companyLicenseList" :url="formData.companyLicenseUrlList"></FileUpload>
      </el-form-item>
      <el-form-item label="企业地址" :rules="rules">
        <el-input v-model="formData.companyAddress" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="印章ID" :rules="rules">
        <el-input v-model="formData.esignSealId" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="支付宝账户" :rules="rules">
        <el-input v-model="formData.alipayAccount" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="银行卡" :rules="rules">
        <el-input v-model="formData.bankCard" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="法人姓名" :rules="rules">
        <el-input v-model="formData.legalName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证正反面" :rules="rules" class="idcard-container">
        <FileUpload class="idcard-uploader" :name.sync="formData.legalIdentityFrontImage" :url="formData.legalIdentityFrontImageUrl">
            身份证人像面
        </FileUpload>
        <FileUpload class="idcard-uploader" :name.sync="formData.legalIdentityBackImage" :url="formData.legalIdentityBackImageUrl">
            身份证国徽面
        </FileUpload>
      </el-form-item>
      <el-form-item label="法定代表人证明" :rules="rules">
        <FileUpload :name.sync="formData.legalProofImage" :url="formData.legalProofImageUrl"></FileUpload>
      </el-form-item>
      <el-form-item label="手机号" :rules="rules">
        <el-input v-model="formData.legalMobile" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" :rules="rules">
        <el-input v-model="formData.legalIdentity" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="分配比例" :rules="rules">
        <el-input v-model="formData.allocateRate" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="联系人邮箱" :rules="rules">
        <el-input v-model="formData.email" placeholder="请输入" size="small"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height:50px"></div>
  </div>
</template>

<script>
import FileUpload from '@/components/file-upload/index.vue'
import { getToken } from '@/utils/auth'
import { addMerchantAPI, getMerchantDetailAPI, editMerchantAPI } from './api'
export default {
  name: 'AddOrEdit',
  components: { FileUpload },
  data() {
    return {
      headers: {
        Authorization: getToken()
      },
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        merchantName: '',
        companyName: '',
        companyIdentity: '',
        companyLicenseList: [],
        companyLicenseUrlList: [],
        alipayAccount: '',
        bankCard: '',
        legalName: '',
        legalMobile: '',
        legalIdentity: '',
        allocateRate: '',
        companyAddress: '',
        esignSealId: '',
        legalProofImage: '',
        legalProofImageUrl: ''
      },
      fileList: []
    }
  },

  mounted() {
    if (this.$route.params.id) this.getMerchantDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editMerchantAPI(this.formData).then(() => {
              this.$router.back()
              this.$message.success('编辑成功')
            })
          } else {
            addMerchantAPI(this.formData).then(() => {
              this.$router.back()
              this.$message.success('新增成功')
            })
          }
        }
      })
    },
    async getMerchantDetail() {
      const res = await getMerchantDetailAPI(this.$route.params.id)
      this.formData = res
    },
    handleAvatarSuccess(res, file) {
      console.log(res)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input {
        width: 500px;
      }
    }
  }
}

.idcard-container {
    /deep/ {
        .el-form-item__content {
            display: flex;
        }
    }

    .idcard-uploader {
        margin-right: 15px;

        /deep/ .el-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 150px;
        }
    }
}
</style>
